.root :global{
  :root{
    --layout-header-height: 64px;
    --layout-footer-height: 58px;
    --layout-content-min-height: calc(100vh - var(--layout-footer-height) - var(--layout-header-height));
}
.wrapper-container {
    max-width: 1170px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;

    &-job {
      max-width: 996px;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width:768px) {
      max-width: 720px;
    }
    .d-flex {
        display: flex
    }
    .ant-btn{
        line-height: 1.5715;
        position: relative;
        display: inline-block;
        font-weight: 400;
        white-space: nowrap;
        text-align: center;
        background-image: none;
        border: 1px solid transparent;
        box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
        cursor: pointer;
        transition: all .3s cubic-bezier(.645,.045,.355,1);
        user-select: none;
        touch-action: manipulation;
        height: 32px;
        padding: 4px 15px;
        font-size: 14px;
        border-radius: 5px;
        color: rgba(0,0,0,.85);
        background: #fff;
        border-color: #d9d9d9;

        &.primary{
            color: #fff;
            background: #1890ff;
            border-color: #1890ff;
            text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
            box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
        }
        
    }
    .align-items-center {
        align-items: center;
    }
    .justify-content-center {
        justify-content: center;
    }

    .err-page {
        
        text-align: center;
        height: calc(100vh);
      }
      .err-page .image img {
        max-width: 100%;
      }
    
      .err-page .title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 8px;
      }
      .err-page .sub-title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 64px;
      }
      .err-page .navigate-button-group {
        justify-content: center;
        max-width: 500px;
        min-width: 300px;
      }
      .mr-4 {
        margin-right: 28px;
      }

      .ant-row-center {
        justify-content: center;
      }
      .h-100 {
        height: 100%;
    }
    .ant-row {
        display: flex;
        flex-flow: row wrap;
    }

  body .h-100 {
      height: 100%;
  }
  .ant-row-middle {
    
      align-items: center;
  }
  .ant-row-center {
  
      justify-content: center;
  }
  }

}
